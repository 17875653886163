import {
  Button,
  Checkbox,
  Icon,
  ImageListItem,
  ImageListItemBar,
} from "@material-ui/core";
import { IVisitDetail } from "../../../state/types/VisitDetails";
import VisitPhoto from "../../inboxMedia/components/visitPhoto";
import { MediaStyles } from "../styles";
import LazyLoad from "react-lazyload";
import dayjs from "dayjs";
import { i18n } from "../../../localizations";
import { useState } from "react";
import clsx from "clsx";
import { getPhotoData } from "../../inboxMedia/state/api";
import JSZip from "jszip";

type Props = {
  visits: IVisitDetail[];
};

const PhotoGrid = (props: Props) => {
  const classes = MediaStyles();

  const [selectedImages, setSelectedImages] = useState<
    { visitId: number; fileName: string }[]
  >([]);

  const handleCheckboxChange =
    (visitId: number, fileName: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedImages((prevSelectedImages) => {
        if (event.target.checked) {
          return [...prevSelectedImages, { visitId, fileName }];
        } else {
          return prevSelectedImages.filter(
            (image) =>
              !(image.visitId === visitId && image.fileName === fileName)
          );
        }
      });
    };

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    await Promise.all(
      selectedImages.map(async (image, index) => {
        const visitCount = selectedImages
          .slice(0, index + 1)
          .filter((img) => img.visitId === image.visitId).length;

        const blob = await getPhotoData(image.visitId, image.fileName, false);
        const base64Data = blob.data.split(",")[1];

        const filename = `${i18n.translate(
          "VISIT_DETAILS_PHOTO_MODAL_Filename_Prefix"
        )}-${image.visitId}-${visitCount}.jpg`;

        const binaryData = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        zip.file(filename, binaryData, { binary: true });
      })
    );

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${i18n.translate("MEDIA_TAB_ZIP_FILE_PREFIX")}.zip`);
  };

  return (
    <>
      <div className={clsx(classes.actionsContainer)}>
        <Button
          variant="contained"
          onClick={handleDownloadAll}
          disabled={!selectedImages.length}
        >
          <Icon
            fontSize="inherit"
            className={clsx(classes.downloadIcon, "fas fa-download")}
          />
          {i18n.translate("MEDIA_TAB_DOWNLOAD_SELECTED")}
        </Button>
      </div>
      {props.visits.map((visit) => {
        if (visit.visitPhotos) {
          return visit.visitPhotos.map((photo) => {
            return (
              <LazyLoad
                key={photo.filename}
                className={classes.flexImage}
                overflow={true}
              >
                <ImageListItem>
                  <Checkbox
                    className={classes.checkbox}
                    onChange={handleCheckboxChange(visit.id, photo.filename)}
                  />
                  <VisitPhoto visitId={visit.id} fileName={photo.filename} />
                  <ImageListItemBar
                    classes={{ subtitle: classes.mediaOverlay }}
                    subtitle={
                      <>
                        <span className={classes.flexRow}>
                          <span>{visit.id}</span>
                          <span>
                            {dayjs(visit.date).format(
                              i18n.translate(
                                "DATE_PERIOD_PICKER_Tooltip_Date_Format"
                              )
                            )}
                          </span>
                        </span>
                        <span className={classes.clippedText}>
                          {visit.branch}
                        </span>
                      </>
                    }
                  />
                </ImageListItem>
              </LazyLoad>
            );
          });
        }
      })}
    </>
  );
};

export default PhotoGrid;
